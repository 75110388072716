<template>
  <CollectionDetails
    is-store-product-preload />
</template>
<script>
import { mapMutations } from 'vuex';
import CollectionDetails from '@/components/CollectionDetails';
export default {
  name: 'MyCollectionDetails',
  components: {
    CollectionDetails,
  },
  destroyed() {
    this.setAccessToken(null);
  },
  methods: {
    ...mapMutations(['setAccessToken']),
  },
};
</script>
